import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const contact = () => {
  return (
    <div>
      <div className="section section-lg pt-5 line-bottom-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 text-center px-4 mb-5 mb-lg-0">
              <div className="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#333"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-mail"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </div>
              <h5 className="mb-3">Email us</h5>
              <p>Email us for general queries.</p>
              <a
                className="font-weight-bold text-primary"
                href="mailto:info@rhiyw.org"
              >
                info@rhiyw.org
              </a>{" "}
              <br />
              <a
                className="font-weight-bold text-primary"
                href="mailto:rhiyw2020@gmail.com"
              >
                rhiyw2020@gmail.com
              </a>{" "}
              <br />
              <p className="pt-4">PO Box: 1293 Kigali</p>
            </div>
            <div className="col-12 col-md-4 text-center px-4 mb-5 mb-lg-0">
              <div className="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#333"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-phone-call"
                >
                  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                </svg>
              </div>
              <h5 className="mb-3">Call us</h5>
              <p>
                Call us to speak to a member of our team. We are always happy to
                help you.
              </p>
              <a
                className="font-weight-bold text-primary"
                href="tel:+250788455135"
              >
                +250 788455135
              </a>
              <br />
              <a
                className="font-weight-bold text-primary"
                href="tel:+250791346384"
              >
                +250 791346384
              </a>
            </div>
            <div className="col-12 col-md-4 text-center px-4">
              <div className="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#333"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
              </div>
              <h5 className="mb-3">Where we are located</h5>
              <p>Our main office:</p>
              <a className="font-weight-bold text-primary" href="#">
                Northern Province,
                <br /> Musanze District, Muhoza Sector
              </a>
              <p className="pt-4">Our liasion office:</p>
              <a className="font-weight-bold text-primary" href="#">
                Kigali City, Kicukiro district,
                <br /> Gikondo sector 2nd floor, <br />
                Building Opposite Mironko plastic industries. <br />
                KK 559 Street
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-lg pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 px-4 mb-5 mb-lg-0">
              <h5 className="mb-3">Our social media</h5>
              <div className="row">
                <div className="col-2">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://www.youtube.com/channel/UCNYxbOqC8ggvUhL9-wjmErQ"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-youtube">
                      <FontAwesomeIcon icon={faYoutube}/>
                    </div>
                    Youtube
                  </a>
                </div>
                <div className="col-2">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://facebook.com/rwandahealthinitiative"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-facebook">
                      <FontAwesomeIcon icon={faFacebookF}/>
                    </div>
                    Facebook
                  </a>
                </div>
                <div className="col-2">
                  <a
                    className="font-weight-bold d-flex"
                    href="https://instagram.com/rhiyw_rwanda?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-instagram">
                      <FontAwesomeIcon icon={faInstagram}/>
                    </div>
                    Instagram
                  </a>
                </div>
                <div className="col-2">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://twitter.com/RhiWomen"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-twitter">
                      <FontAwesomeIcon icon={faTwitter}/>
                    </div>
                    Twitter
                  </a>
                </div>
                <div className="col-2">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://www.linkedin.com/posts/rhiyw-rwanda_rhiyw-rwanda-health-initiative-for-youth-activity-7273314433404227584-r1ok"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-twitter">
                      <FontAwesomeIcon icon={faLinkedinIn}/>
                    </div>
                    Linkedin
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default contact
