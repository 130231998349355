import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact-us"
import Teamimage from "./../images/office-picture.jpeg"

const SecondPage = () => (
  <Layout>
    <SEO title="Contact us" />
    <section className="section-header text-white overlay-soft-primary" style={{backgroundImage: "url("+Teamimage+")", backgroundColor: '#cccccc', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative'}}>
      <div className="container-fluid">
        <div className="row justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">Contact us</h1>
          </div>
        </div>
      </div>
    </section>
    <Contact />
  </Layout>
)

export default SecondPage
